import { template as template_b3a42bc634394b0783000b5216cb185e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_b3a42bc634394b0783000b5216cb185e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
