import { template as template_a487952306e54c9caf9e478480ff484b } from "@ember/template-compiler";
const WelcomeHeader = template_a487952306e54c9caf9e478480ff484b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
