import { template as template_47b04b3ea9ed497bbdb3df33384515ed } from "@ember/template-compiler";
const FKLabel = template_47b04b3ea9ed497bbdb3df33384515ed(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
